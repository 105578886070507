import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from "./layout/components/main-layout/main-layout.component";
import { AboutUsPageComponent } from "./features/about-us/about-us-page/about-us-page.component";
import { ContactPageComponent } from "./features/contact/pages/contact-page/contact-page.component";
import { EventListEmbeddedComponent } from "./features/events/pages/event-list-embedded/event-list-embedded.component";
import { EventListByHostComponent } from "./features/events/pages/event-list-by-host/event-list-by-host.component";
import {
  ClubAvailabilityEmbeddedComponent
} from "./features/clubs/pages/club-availability-embedded/club-availability-embedded.component";
import { ClubResolver } from "./features/clubs/resolvers/club.resolver";

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    data: {
      mainLayout: {
        constrainWidth: true
      }
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./features/players/players.module').then(m => m.PlayersModule)
      },
      {
        path: '',
        loadChildren: () => import('./features/player-self/player-self.module').then(m => m.PlayerSelfModule)
      },
      {
        path: '',
        loadChildren: () => import('./features/legal/legal.module').then(m => m.LegalModule)
      },
      {
        path: 'about-us',
        component: AboutUsPageComponent,
        data: {
          title: $localize `About Us`,
          description: $localize `We want to provide more people with access to the sport of Tennis & Padel and support local clubs to survive & thrive in this rapidly changing world. 🎾`,
          // ogUrl: 'https://www.example.com/players'
        }
      },
      {
        path: 'contact',
        component: ContactPageComponent,
        data: {
          title: $localize `Contact Us`,
          description: $localize `If you have an idea how we can improve or you want to get in touch with us, we would be happy to hear from you! 🎾`,
          // ogUrl: 'https://www.example.com/players'
        }
      },
    ]
  },
  {
    path: 'embedded',
    children: [
      {
        path: 'events',
        component: EventListEmbeddedComponent,
      },
      {
        path: 'court-availability',
        component: ClubAvailabilityEmbeddedComponent,
        resolve: {
          club: ClubResolver
        }
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    data: {
      mainLayout: {
        constrainWidth: false
      }
    },
    children: [
      {
        path: 'dahlemer-tc',
        data: {
          title: `Events des Dahlemer Tennisclub e.V.`,
          description: `Fitness Tennis und mehr`,
          hostId: '29e649fa-a7ed-4715-8a9c-3df768908344',
          hostName: 'Dahlemer Tennisclub e.V.'
        },
        children: [
          {
            path: '',
            component: EventListByHostComponent,
          },
        ],
      },
      {
        path: '',
        loadChildren: () => import('./features/events/events.module').then(m => m.EventsModule),
      },
      {
        path: '',
        loadChildren: () => import('./features/clubs/clubs.module').then(m => m.ClubsModule)
      },
    ],
  },
  {
    path: '',
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)
  },
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', initialNavigation: 'enabledBlocking', bindToComponentInputs: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
