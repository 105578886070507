import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "../../../environments/environment";
import Hotjar from "@hotjar/browser";

@Injectable({
  providedIn: 'root'
})
export class HotjarService {

  constructor(private router: Router) {}

  public initialize() {
    this.onRouteChange();

    Hotjar.init(
      Number(environment.hotjarTrackingCode),
      Number(environment.hotjarVersion)
    );

    // console.log('Hotjar initialized');
  }

  // track visited routes
  private onRouteChange() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        Hotjar.stateChange(event.urlAfterRedirects);
        // console.log('Sending Hotjar tracking for: ', event.urlAfterRedirects);
        // console.log('Hotjar tracking code: ', environment.hotjarTrackingCode);
        // console.log('Hotjar version: ', environment.hotjarVersion);
      }
    });
  }
}
