import { Component } from '@angular/core';
import { RouterLink } from "@angular/router";
import { LanguageToggleComponent } from "../../../shared/components/language-toggle/language-toggle.component";

@Component({
  selector: 'cs-footer',
  templateUrl: './footer.component.html',
  standalone: true,
  imports: [
    RouterLink,
    LanguageToggleComponent
  ],
  styles: ``
})
export class FooterComponent {

}
