import { afterNextRender, Component, Inject, inject, LOCALE_ID, OnInit } from '@angular/core';
import { BranchIoService } from "./shared/services/branch-io.service";
import { environment } from "../environments/environment";
import { HotjarService } from "./shared/services/hotjar.service";
import {
  ActivatedRoute,
  GuardsCheckEnd,
  GuardsCheckStart,
  NavigationCancel,
  NavigationEnd,
  Router
} from "@angular/router";
import { filter, map, mergeMap } from "rxjs";
import { SEOService } from "./shared/services/seo.service";
import { GoogleAnalyticsService } from "./shared/services/google-analytics.service";
import { PrimeNGConfig, Translation } from "primeng/api";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {

  showAppConfig = !environment.production;
  guardLoading = false;

  constructor(private readonly branchIoService: BranchIoService, private readonly googleAnalyticsService: GoogleAnalyticsService,
              private readonly hotjarService: HotjarService, private readonly router: Router, private readonly activatedRoute: ActivatedRoute, private readonly seoService: SEOService,
              @Inject(LOCALE_ID) public locale: string) {
    if (environment.hotjarTrackingCode && environment.hotjarVersion) {
      this.hotjarService.initialize();
    }

    afterNextRender({ write: () => {
        this.branchIoService.init();
        this.googleAnalyticsService.initialize();
    } }, );
  }

  primeNgConfig = inject(PrimeNGConfig);

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof GuardsCheckStart) {
        this.guardLoading = true;
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.guardLoading = false;
      }
    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this.seoService.updateTitle(event['title']);
        this.seoService.updateOgUrl(event['ogUrl']);
        //Updating Description tag dynamically with title
        this.seoService.updateDescription(event['title'] + event['description'])
      });

    this.setPrimeNgConfig();
  }

  setPrimeNgConfig() {
    const localeTranslations: Record<string, Translation> = {
      'en': {
        dateFormat: 'mm/dd/yy',
      },
      'de': {
        dateFormat: 'dd.mm.yy',
      }
    }
    console.log('setPrimeNgConfig', this.locale);
    this.primeNgConfig.setTranslation(localeTranslations[this.locale]);

  }
}
