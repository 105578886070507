import { Component } from '@angular/core';
import { NgForOf } from "@angular/common";

interface TeamMember {
  name: string;
  title: string;
  imagePath: string;
}

@Component({
  selector: 'cs-the-team-section',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './the-team-section.component.html',
  styleUrl: './the-team-section.component.scss'
})
export class TheTeamSectionComponent {

   teamMembers: TeamMember[] = [
    {
      name: 'Max Holz',
      title: 'Product & Design',
      imagePath: 'assets/img/team/max.png'
    },
    {
      name: 'Lion Reinacher',
      title: 'Software Engineer',
      imagePath: 'assets/img/team/lion.png'
    },
    {
      name: 'Pablo Schlesselmann',
      title: 'Software Engineer',
      imagePath: 'assets/img/team/pablo.jpeg'
    },
    {
      name: 'Paddy Shanley',
      title: 'UI/UX Designer',
      imagePath: 'assets/img/team/paddy.jpg'
    },
    {
      name: 'Lorenz Ewaldsen',
      title: 'Social Media',
      imagePath: 'assets/img/team/lorenz.jpg'
    }
  ];
}
