<div class="main-content">
    <app-hero>
        <p title i18n>Making connection a priority</p>
        <p subtitle i18n>We are more connected than ever in our modern world, yet we feel more alone than before. Therefore we at CircleSquare make connection a priority, and what better way to connect with people than over the sports you love?</p>
        <div image>
            <img class="hero-image" width="100%"  height="auto" src="assets/img/photo_hi5.jpeg" i18n-alt alt="A female and a male tennis player giving each other a high five at the net"/>
        </div>
    </app-hero>

    <app-feature-section variant="text-image">
        <img src="assets/img/illustration_man.png" class="w-7 md:w-9" i18n-alt alt="An abstract tennis player" image>
        <h3 class="title" i18n="feature.item1.title">Giving Players Access</h3>
        <p class="subtitle" i18n="feature.item1.description">We want to start by creating more access to the sport of tennis & padel, empowering anyone to play and find the perfect partner and the right court.</p>
    </app-feature-section>

    <app-feature-section>
        <img src="assets/img/illustration_woman.png" class="w-7 md:w-9" i18n-alt alt="An abstract female tennis player using her backhand" image>
        <h3 class="title" i18n="feature.item2.title">Helping Local Clubs</h3>
        <p class="subtitle" i18n="feature.item2.description">We strongly believe local clubs are vital to the sports community and play an essential role in society. Therefore we are constantly working on adding new features to increase court bookings and membership takeups to help clubs thrive in the modern, digital world.</p>
    </app-feature-section>

    <app-feature-section variant="text-image">
        <img src="assets/img/illustration_players.png" class="w-8 md:w-9" i18n-alt alt="Two abstract tennis players" image>
        <h3 class="title" i18n="feature.item3.title">Our Vision</h3>
        <p class="subtitle" i18n="feature.item3.description">Our vision is to build the sports community of the future, empowering people to connect and stay healthy through the sport they love.</p>
        <p class="subtitle" i18n="feature.item3.descriptionContinuation">If you have an idea or interest in exploring with us what features might be of interest to players or club, please contact us.</p>
    </app-feature-section>


    <cs-the-team-section></cs-the-team-section>

</div>
