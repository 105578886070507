import { Component } from '@angular/core';
import { HeroComponent } from "../../../shared/components/hero/hero.component";
import { FeatureSectionComponent } from "../../../shared/components/feature-section/feature-section.component";
import { TheTeamSectionComponent } from "../components/the-team-section/the-team-section.component";

@Component({
  selector: 'cs-about-us-page',
  standalone: true,
  imports: [
    HeroComponent,
    FeatureSectionComponent,
    TheTeamSectionComponent
  ],
  templateUrl: './about-us-page.component.html',
  styleUrl: './about-us-page.component.scss'
})
export class AboutUsPageComponent {

}
