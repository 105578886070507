import { Component } from '@angular/core';
import { ContactCreateGQL, ContactOrigin, CreateContactInput } from "../../../../shared/graphql/generated/graphql";
import { FormBuilder, ReactiveFormsModule, Validators } from "@angular/forms";
import { InputTextareaModule } from "primeng/inputtextarea";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { MessageModule } from "primeng/message";
import { CommonModule } from "@angular/common";
import { InputTextModule } from "primeng/inputtext";
import { FormErrorComponent } from "../../../../shared/components/form-error/form-error.component";

@Component({
  selector: 'cs-contact-page',
  standalone: true,
  imports: [
    FormErrorComponent,
    InputTextareaModule,
    ReactiveFormsModule,
    ButtonModule,
    RippleModule,
    MessageModule,
    CommonModule,
    InputTextModule
  ],
  templateUrl: './contact-page.component.html',
  styleUrl: './contact-page.component.scss'
})
export class ContactPageComponent {
  contactForm = this.fb.group({
    name: [''],
    email: ['', [Validators.required, Validators.email]],
    phone: [''],
    message: ['', [Validators.required]],
  });

  isSubmitting = false;
  showSuccess = false;
  showError = false;

  constructor(private contactCreateGQL: ContactCreateGQL, private fb: FormBuilder) {
  }

  onSubmit() {

    const payload: CreateContactInput = {
      name: this.contactForm.value.name,
      email: this.contactForm.value.email!,
      phone: this.contactForm.value.phone,
      message: this.contactForm.value.message,
      origin: ContactOrigin.CirclesquareWebsite,
      topic: 'contact-form',
    }

    this.showSuccess = false;
    this.showError = false;
    this.isSubmitting = true;
    this.contactCreateGQL.mutate({data: payload}).subscribe({
      next: () => {
        this.showSuccess = true;
        this.contactForm.reset();
        this.isSubmitting = false;
      },
      error: (err) => {
        this.showError = true;
        console.log('err', err);
        this.isSubmitting = false;
      }
    })
  }
}
