import {
  afterNextRender, AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef, inject,
  Inject,
  LOCALE_ID, NgZone, OnChanges, OnDestroy,
  PLATFORM_ID,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Subscription } from "rxjs";
import { EventListEmbeddedStore } from "../../../features/events/pages/event-list-embedded/event-list-embedded.store";
import { DOCUMENT, isPlatformBrowser } from "@angular/common";
import { ActivatedRoute, Params } from "@angular/router";
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'cs-embedded-component-base',
  standalone: true,
  imports: [],
  styles: ``,
  template: ``
})
export abstract class EmbeddedComponentBaseComponent implements OnDestroy, AfterViewInit, OnChanges {

  @ViewChild('embeddedElement', { read: ElementRef }) embeddedElementRef?: ElementRef;

  backgroundColor?: string;
  containerId?: string;

  queryParamsSubscription?: Subscription;

  private resizeObserver?: ResizeObserver;

  private renderer = inject(Renderer2);
  private document = inject(DOCUMENT);

  protected locale = inject(LOCALE_ID);
  protected platformId = inject(PLATFORM_ID);
  protected route = inject(ActivatedRoute);

  private changeDetect = inject(ChangeDetectorRef);
  private ngZone = inject(NgZone);

  constructor() {
    afterNextRender(() => {
      this.subscribeRouteParameterChanges();
      this.afterNextRender();
    });
  }

  afterNextRender() {

  }

  ngOnInit() {

  }

  get fallbackHeight(): number {
    return 350;
  }

  get minHeight(): number | undefined | null {
    return undefined;
  }

  subscribeRouteParameterChanges() {
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      console.log('Params:', params);

      this.backgroundColor = params['background'];
      if (!this.backgroundColor) {
        this.backgroundColor = 'var(--surface-ground)';
      }

      this.containerId = params['containerId'];
      console.log('Container ID:', this.containerId);

      this.renderer.setStyle(this.document.body, 'background', this.backgroundColor);
      this.renderer.setStyle(this.document.body, 'min-height', 'unset');

      this.onQueryParamsChange(params);
    });
  }

  onQueryParamsChange(params: Params): void {

  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      console.log('After View Init');
      this.setupResizeObserver();
    }

    console.log('OnInit', this.embeddedElementRef);
  }

  ngOnChanges() {
    console.log('After View Changed');
    this.updateHeight();
  }

  private setupResizeObserver() {
    this.ngZone.runOutsideAngular(() => {
      this.resizeObserver = new ResizeObserver(() => this.sendHeight());
      this.resizeObserver.observe(this.embeddedElementRef?.nativeElement);
    });

    setTimeout(() => this.sendHeight(), 0);
  }

  private sendHeight() {
    this.changeDetect.detectChanges();
    if (!this.embeddedElementRef) {
      return;
    }
    const element = this.embeddedElementRef.nativeElement;
    let height = this.embeddedElementRef.nativeElement.offsetHeight;
    if (height < 100) {
      height = this.fallbackHeight;
    }
    if (this.minHeight && height < this.minHeight) {
      height = this.minHeight;
    }
    console.log('setHeight:', height, this.containerId);
    window.parent.postMessage({ type: 'setHeight', height, containerId: this.containerId }, '*');
  }

  public updateHeight() {
    this.sendHeight();
  }

  ngOnDestroy(): void {
    // Set to your default or previous color
    this.renderer.setStyle(this.document.body, 'background', 'var(--surface-card)');
    this.renderer.setStyle(this.document.body, 'min-height', '100%');

    this.queryParamsSubscription?.unsubscribe();

    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

}
