import { catchError, map, switchMap } from 'rxjs/operators';
import * as PlayerSelfActions from './player-self.actions';
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { PlayerSelfService } from "../services/player-self.service";
import * as AuthActions from "../../auth/store/auth.actions";

@Injectable()
export class PlayerSelfEffects {
  loadPlayerSelf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess),
      switchMap(() =>
        this.playerSelfService.loadPlayerSelf().pipe(
          map(playerSelf => PlayerSelfActions.loadPlayerSelfSuccess({ playerSelf })),
          catchError(error => of(PlayerSelfActions.loadPlayerSelfFailure({ error })))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private playerSelfService: PlayerSelfService
  ) {}
}
