import { Component } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from "@angular/router";
import { NavBarComponent } from "../nav-bar/nav-bar.component";
import { FooterComponent } from "../footer/footer.component";
import { NgClass } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
@Component({
  selector: 'cs-main-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    NavBarComponent,
    FooterComponent,
    FooterComponent,
    NavBarComponent,
    NgClass
  ],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent {

  constrainWidth = true;

  constructor(protected activatedRoute: ActivatedRoute) {
    if (activatedRoute.snapshot.data['mainLayout'] != null && activatedRoute.snapshot.data['mainLayout']['constrainWidth'] === false) {
      this.constrainWidth = false;
    }
  }

  ngOnInit() {
  }

}
