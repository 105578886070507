import { Component, DestroyRef, inject, Input, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { AsyncPipe, isPlatformBrowser, NgForOf, NgIf } from "@angular/common";
import { ClubOpeningHourTimesPipe } from "../../pipes/club-opening-hour-times.pipe";
import { ClubOpeningHoursDaysTrPipe } from "../../pipes/club-opening-hours-days-tr.pipe";
import { CourtsAvailabilityComponent } from "../../components/court-availability/court-availability.component";
import { DividerModule } from "primeng/divider";
import { EventsWrapperComponent } from "../../../events/components/events-wrapper/events-wrapper.component";
import {
    PaymentProcessingStatusDialogComponent
} from "../../../../shared/components/payment-processing-status-dialog/payment-processing-status-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ClubStore } from "../club-detail-page/club-detail-page.store";
import { EventListEmbeddedStore } from "../../../events/pages/event-list-embedded/event-list-embedded.store";
import { SEOService } from "../../../../shared/services/seo.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  EmbeddedComponentBaseComponent
} from "../../../../shared/components/embedded-component-base/embedded-component-base.component";

@Component({
  selector: 'cs-club-availability-embedded',
  standalone: true,
    imports: [
        AsyncPipe,
        ClubOpeningHourTimesPipe,
        ClubOpeningHoursDaysTrPipe,
        CourtsAvailabilityComponent,
        DividerModule,
        EventsWrapperComponent,
        NgForOf,
        NgIf,
        PaymentProcessingStatusDialogComponent
    ],
  templateUrl: './club-availability-embedded.component.html',
  styleUrl: './club-availability-embedded.component.scss'
})
export class ClubAvailabilityEmbeddedComponent extends EmbeddedComponentBaseComponent {

  private destroyRef = inject(DestroyRef);

  clubStore: ClubStore = inject(ClubStore);

  @Input() clubId?: string;

  override get minHeight(): number | null | undefined {
    return 400;
  }


  override ngOnInit() {
    super.ngOnInit();
    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data => {
      if (data['club']) {
        const club = data['club'];

        this.clubStore.setClub(club);
      }
    }));
  }

}
