import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, mergeMap, of, tap } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import * as AuthActions from './auth.actions';
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { Store } from "@ngrx/store";
import { showErrorToast } from "../../../shared/store/toast/toast.actions";
import { refreshTokenSuccess } from "./auth.actions";

@Injectable()
export class AuthEffects {

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private readonly store: Store
  ) {}

  loginWithGoogle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithGoogle),
      switchMap(({ idToken }) =>
        this.authService.loginWithGoogle(idToken).pipe(
          map(credentials => {
            return credentials;
          }),

          map(credentials => AuthActions.loginSuccess({ credentials })),
          catchError(error => {
            // this.store.dispatch(showErrorToast({ error }));
            return of(AuthActions.loginFailed({ error }));
          })
        )
      )
    )
  );

  loginWithApple$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithApple),
      switchMap(({ idToken, firstName }) =>
        this.authService.loginWithApple(idToken, firstName).pipe(
          map(credentials => {
            return credentials;
          }),
          map(credentials => AuthActions.loginSuccess({ credentials })),
          catchError(error => {
            // this.store.dispatch(showErrorToast({ error }));
            return of(AuthActions.loginFailed({ error }));
          })
        )
      )
    )
  );

  checkGoogleAuthState$ = createEffect(() =>
    this.socialAuthService.authState.pipe(
      mergeMap((user) => {
        if (user) {
          // Assuming the user object contains an idToken or some identifier you can use
          return of(AuthActions.loginWithGoogle({ idToken: user.idToken }));
        } else {
          // No user found, you might want to handle this case differently
          return EMPTY; // Make sure to import EMPTY from 'rxjs'
        }
      })
    )
  );

  storeCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginSuccess, refreshTokenSuccess),
      tap(({ credentials }) => {
        this.authService.storeCredentials(credentials);
      })
    ),
    { dispatch: false }
  );

  clearCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.logout),
      tap(() => {
        this.authService.clearCredentials();
      })
    ),
    { dispatch: false });

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.refreshToken),
      mergeMap(() =>
        this.authService.refreshToken().pipe(
          map(tokenPair => AuthActions.refreshTokenSuccess({
            credentials: tokenPair
          })),
          catchError(error => of(AuthActions.refreshTokenFailure({ error })))
        )
      )
    )
  );

}
