import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as ToastActions from "./toast.actions";
import { catchError, map, switchMap } from "rxjs/operators";
import { EMPTY, mergeMap, of, tap } from "rxjs";
import { AuthService } from "../../../features/auth/services/auth.service";
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { MessageService } from "primeng/api";

@Injectable()
export class ToastEffects {

  constructor(
    private actions$: Actions,
    private messageService: MessageService,
  ) {}

  showErrorToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToastActions.showErrorToast),
      switchMap(({ error }) => {
        let errorMessage;

        if (error.message) {
          errorMessage = error.message;
        } else {
          errorMessage = error;
        }

        console.log('Show Error Toast', errorMessage);

        this.messageService.add({severity: 'error', summary: $localize `Error`, detail: errorMessage});
        return EMPTY;
      })
    ), { dispatch: false }
  );
}
