<div class="grid grid-nogutter surface-section px-4 py-4 md:px-6 lg:px-8 border-top-1 surface-border">
  <div class="col-12 lg:col-6 lg:border-right-1 surface-border">
    <img src="assets/logo.svg" class="w-10rem mx-auto lg:mx-0" alt="CircleSquare Logo">
    <span class="text-900 block mt-4 mr-3">Connecting people through the sport they love</span>
    <span class="text-500 block mt-4">© 2023, KreisViereck UG</span>
  </div>
  <div class="col-12 lg:col-3 md:col-6 flex mt-4 lg:mt-0 lg:pl-4 flex-column">
    <span class="text-900 text-xl font-medium block" i18n>Legal</span>
    <ul class="list-none p-0">
      <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" i18n routerLink="/imprint">Imprint</a></li>
      <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" i18n routerLink="/terms-of-service">Terms of service</a></li>
      <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" i18n routerLink="/privacy-policy">Privacy Policy</a></li>
    </ul>
  </div>
  <div class="col-12 md:col-6 lg:col-3 mt-4 lg:mt-0 lg:pl-4 flex flex-column justify-content-between">
    <div>
      <span class="text-900 text-xl font-medium block" i18n>Social</span>
      <ul class="list-none p-0">
        <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" href="https://www.instagram.com/circlesquare.app">Instagram</a></li>
      </ul>
    </div>
    <cs-language-toggle class="align-self-end"></cs-language-toggle>
  </div>
</div>
