import { Component, Inject, LOCALE_ID } from '@angular/core';
import { SelectButtonChangeEvent, SelectButtonModule } from "primeng/selectbutton";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { DialogService } from "primeng/dynamicdialog";
import { FormsModule } from "@angular/forms";

@Component({
  selector: 'cs-language-toggle',
  standalone: true,
  imports: [
    SelectButtonModule,
    FormsModule
  ],
  templateUrl: './language-toggle.component.html',
  styleUrl: './language-toggle.component.scss'
})
export class LanguageToggleComponent {

  constructor(public router: Router, @Inject(LOCALE_ID) public locale: string) {
  }

  onChangeLocale(event: SelectButtonChangeEvent) {
    const lang = event.value;
    const url = this.router.url;
    window.location.href = `/${lang}${url}`;
  }
}
