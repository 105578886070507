import {
  afterNextRender,
  AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef, inject,
  Inject,
  LOCALE_ID,
  NgZone, OnChanges,
  OnDestroy, PLATFORM_ID,
  Renderer2,
  ViewChild
} from '@angular/core';
import { EventListEmbeddedStore } from "./event-list-embedded.store";
import { EventType } from "../../../../shared/graphql/generated/graphql";
import { EventsWrapperComponent } from "../../components/events-wrapper/events-wrapper.component";
import { AsyncPipe, DOCUMENT, isPlatformBrowser } from "@angular/common";
import { environment } from "../../../../../environments/environment";
import { ActivatedRoute, Params } from "@angular/router";
import { Subscription } from "rxjs";
import { platform } from "node:os";
import {
  EmbeddedComponentBaseComponent
} from "../../../../shared/components/embedded-component-base/embedded-component-base.component";

@Component({
  selector: 'cs-event-list-embedded',
  standalone: true,
  imports: [
    EventsWrapperComponent,
    AsyncPipe
  ],
  host: {ngSkipHydration: 'true'},
  templateUrl: './event-list-embedded.component.html',
  styleUrl: './event-list-embedded.component.scss',
  providers: [EventListEmbeddedStore]
})
export class EventListEmbeddedComponent extends EmbeddedComponentBaseComponent {

  hostId?: string;
  take = 100;

  public readonly eventListEmbeddedStore = inject(EventListEmbeddedStore);

  override afterNextRender() {
    this.eventListEmbeddedStore.state$.subscribe(state => {
      setTimeout(() => this.updateHeight(), 100);
    });
  }

  onEventClick(eventId: string) {
    console.log('Embedded Event clicked', eventId);
    const url = `${environment.appUrl}/event/${eventId}`;
    const newWindow = window.open(url, '_blank'); // Open a new window
    if (newWindow) {
      setTimeout(() => {
        newWindow.location.href = url;
      }, 100); // Set the URL after a short delay
    } else {
      alert('Please allow popups for this website');
    }
  }

  override onQueryParamsChange(params: Params) {
    this.hostId = params['hostId'];
    if (!this.hostId) {
      this.hostId = '549a51b0-0dc3-41ad-8627-79009a84ff1f'; // 9d06b26f-2667-43c8-8580-b86ffa67b751
    }

    this.eventListEmbeddedStore.loadEvents({ take: this.take, offset: 0, organizerId: this.hostId, lang: this.locale });
  }

}
