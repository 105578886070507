import { Component, Inject, Renderer2 } from '@angular/core';
import { EventListEmbeddedStore } from "../event-list-embedded/event-list-embedded.store";
import { EventsWrapperComponent } from "../../components/events-wrapper/events-wrapper.component";
import { AsyncPipe, DOCUMENT } from "@angular/common";
import { environment } from "../../../../../environments/environment";
import { ServiceSectionComponent } from "../../../../shared/components/service-section/service-section.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'cs-event-list-by-host',
  standalone: true,
  imports: [
    EventsWrapperComponent,
    AsyncPipe,
    ServiceSectionComponent
  ],
  host: {ngSkipHydration: 'true'},
  templateUrl: './event-list-by-host.component.html',
  styleUrl: './event-list-by-host.component.scss',
  providers: [EventListEmbeddedStore]
})
export class EventListByHostComponent {

  hostId!: string;
  hostName!: string;

  constructor(public readonly eventListEmbeddedStore: EventListEmbeddedStore, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document, private route: ActivatedRoute, private router: Router) {

  }

  onEventClick(eventId: string) {
    const url = `${environment.appUrl}/event/${eventId}`;
    const newWindow = window.open(url, '_blank'); // Open a new window
    if (newWindow) {
      setTimeout(() => {
        newWindow.location.href = url;
      }, 100); // Set the URL after a short delay
    } else {
      alert('Please allow popups for this website');
    }
  }

  ngOnInit() {
    this._hostIdAndNameFromRouteData();
    this.eventListEmbeddedStore.loadEvents({ take: 100, offset: 0, organizerId: this.hostId });
  }

  _hostIdAndNameFromRouteData() {
    const hostId = this.route.snapshot.data['hostId'];
    const hostName = this.route.snapshot.data['hostName'];

    if (hostId && hostName) {
      this.hostId = hostId;
      this.hostName = hostName;
    } else {
      console.error('Host ID and Name are required for this page');
      this.router.navigate(['/']);
    }
  }

}
