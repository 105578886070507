import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  AuthAppleOAuthGQL,
  AuthGoogleOAuthGQL, AuthRefreshTokenGQL,
  TokenPair
} from "../../../shared/graphql/generated/graphql";
import { map, Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { loginSuccess } from "../store/auth.actions";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { isPlatformBrowser } from "@angular/common";
import { mapApolloMutationResultDataOrThrowOnError } from "../../../shared/graphql/graphql-utils";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private authGoogleOAuthGQL: AuthGoogleOAuthGQL, private authAppleOAuthGQL: AuthAppleOAuthGQL, private authRefreshTokenGQL: AuthRefreshTokenGQL, private store: Store) {
    this.initializeAuthState();
  }

  loginWithGoogle(idToken: string) {
    return this.authGoogleOAuthGQL.mutate({
      idToken: idToken,
      origin: 'WEB'
    }).pipe(
      mapApolloMutationResultDataOrThrowOnError(),
      map(data => {
        return {
          id: data.authGoogleOAuth.tokenPairId,
          accessToken: data.authGoogleOAuth.accessToken,
          refreshToken: data.authGoogleOAuth.refreshToken,
        } as TokenPair;
      })
    );
  }

  loginWithApple(idToken: string, firstName?: string) {
    return this.authAppleOAuthGQL.mutate({
      idToken: idToken,
      firstName: firstName
    }).pipe(
      mapApolloMutationResultDataOrThrowOnError(),
      map(data => {
        return {
          id: data.authAppleOAuth.tokenPairId,
          accessToken: data.authAppleOAuth.accessToken,
          refreshToken: data.authAppleOAuth.refreshToken,
        } as TokenPair;
      })
    );
  }

  storeCredentials(credentials: TokenPair) {
    let serializedCredentials = JSON.stringify(credentials);
    localStorage.setItem('credentials', serializedCredentials);
  }

  clearCredentials() {
    localStorage.removeItem('credentials');
  }

  getStoredCredentials(): TokenPair | null {
    let serializedCredentials = localStorage.getItem('credentials');
    if (serializedCredentials) {
      return JSON.parse(serializedCredentials);
    }
    return null;
  }

  isTokenValid(token: string = ''): boolean {
    if (token === '') return false

    const decoded = jwtDecode<JwtPayload>(token);
    if (!decoded.exp) return false;

    return decoded.exp * 1000 >= new Date().getTime()
  }

  initializeAuthState() {
    if (!isPlatformBrowser(this.platformId)) return;

    let credentials = this.getStoredCredentials();
    if (credentials && this.isTokenValid(credentials?.accessToken)) {
      console.log('AuthService - initializeAuthState - stored credentials are valid');
      // wait 10ms to let the store initialize
      setTimeout(() => {
        this.store.dispatch(loginSuccess({ credentials: credentials! }));
      }, 10);
    } else if (credentials && !this.isTokenValid(credentials?.accessToken) && this.isTokenValid(credentials?.refreshToken)) {
      console.log('AuthService - initializeAuthState - stored credentials are invalid, refreshing');
      this.refreshToken().subscribe(
        {
          next: (tokenPair) => {
            this.storeCredentials(tokenPair);
            console.log('AuthService - initializeAuthState - refreshed token', tokenPair);
            this.store.dispatch(loginSuccess({ credentials: tokenPair }));
          },
          error: (error) => {
            console.log('AuthService - initializeAuthState - error refreshing token', error);
            this.clearCredentials();
          }
        }
      );
    } else {
      console.log('AuthService - initializeAuthState - stored credentials are invalid');
      this.clearCredentials();
    }
  }

  refreshToken(): Observable<TokenPair> {
    let credentials = this.getStoredCredentials();
    if (!credentials) {
      throw new Error('No stored credentials found');
    }
    return this.authRefreshTokenGQL.mutate({}, {
      context: {
        headers: {
          Authorization: `Bearer ${credentials.refreshToken}`
        }
      }
    }).pipe(
      mapApolloMutationResultDataOrThrowOnError(),
      map(data => {
        return {
          id: data.authRefreshToken.id,
          accessToken: data.authRefreshToken.accessToken,
          refreshToken: data.authRefreshToken.refreshToken,
        } as TokenPair;
      })
    );
  }
}
