<section>
  <h3 class="text-center title mb-3" i18n>The Team</h3>
  <div class="text-center subtitle mb-5 md:mb-8" i18n>We are passionate about sports. Always up for a good match or challenge!</div>
  <div class="grid">
    <div class="col-6 md:col-4 text-center mb-2 md:mb-5" *ngFor="let member of teamMembers">
      <img [src]="member.imagePath" class="mb-3 rounded-image" [alt]="member.name"/>
      <div class="text-lg md:text-xl text-900 font-medium mb-2">{{ member.name }}</div>
      <div class="text-primary-600 text-lg md:text-xl mb-3">{{ member.title }}</div>
    </div>
  </div>
</section>
