<div class="main-content">
  <div class="landing-section">
    <div class="content-container px-4 md:px-0 pt-4">
      <h2 class="title mb-4 md:mb-6 text-center" i18n>Events of {{ hostName }}</h2>
    </div>
    <div class="surface-ground">
      <div class="content-container px-4 md:px-0">
      <section>
        <cs-events-wrapper class="w-full" [events]="(eventListEmbeddedStore.events$ | async)!"
                           [loading]="(eventListEmbeddedStore.isLoading$ | async)!"></cs-events-wrapper>
      </section>
    </div>
    </div>
  </div>
</div>
