import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppLayoutModule } from "./layout/app.layout.module";
import { AppConfigModule } from "./layout/config/app.config.module";
import { NgxsModule } from "@ngxs/store";
import { EventsState } from "./features/events/state/events.state";
import { environment } from "../environments/environment";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsStoragePluginModule } from "@ngxs/storage-plugin";
import { AUTH_STATE, STORAGE_STATE } from "../state";
import { provideHttpClient, withFetch, withInterceptorsFromDi } from "@angular/common/http";
import { GraphQLModule } from "./shared/graphql/graphql.module";
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers, PLAYER_SELF_STATE } from "./reducers";
import { LegalModule } from "./features/legal/legal.module";
import { AngularSvgIconModule } from "angular-svg-icon";
import { GoogleLoginProvider, SocialAuthServiceConfig } from "@abacritt/angularx-social-login";
import { AuthModule } from "./features/auth/auth.module";
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from "./features/auth/store/auth.effects";
import { PlayerSelfEffects } from "./features/player-self/store/player-self.effects";
import { playerSelfReducer } from "./features/player-self/store/player-self.reducers";
import { authReducer } from "./features/auth/store/auth.reducers";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { NgxStripeModule } from "ngx-stripe";
import { DialogService } from "primeng/dynamicdialog";
import { ConfirmationService, MessageService } from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ToastEffects } from "./shared/store/toast/toast.effects";
import { ToastModule } from "primeng/toast";
import { DialogModule } from "primeng/dialog";
import { ProgressBarModule } from "primeng/progressbar";
import { registerLocaleData } from "@angular/common";
import localeEn from '@angular/common/locales/en';
import localeDe from '@angular/common/locales/de';
import { PortalModule } from "@angular/cdk/portal";
import { OverlayModule } from "@angular/cdk/overlay";
import { NgxTippyModule } from "ngx-tippy-wrapper";
import { popperVariation, provideTippyConfig, tooltipVariation } from "@ngneat/helipopper";

registerLocaleData(localeEn);
registerLocaleData(localeDe);

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [EffectsModule.forRoot([AuthEffects, PlayerSelfEffects, ToastEffects]),
        EffectsModule.forFeature([AuthEffects, PlayerSelfEffects, ToastEffects]),
        StoreModule.forRoot(reducers, {
            metaReducers,
        }),
        StoreModule.forFeature(PLAYER_SELF_STATE, playerSelfReducer),
        StoreModule.forFeature(AUTH_STATE, authReducer),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        AngularSvgIconModule.forRoot(),
        AppConfigModule,
        BrowserModule,
        AppRoutingModule,
        AppLayoutModule,
        AppConfigModule,
        GraphQLModule,
        LegalModule,
        AuthModule,
        NgxsModule.forRoot([
            EventsState,
        ], { developmentMode: !environment.production }),
        NgxsLoggerPluginModule.forRoot({ disabled: environment.production }),
        NgxStripeModule.forRoot(),
        ConfirmDialogModule,
        ToastModule,
        DialogModule,
        ProgressBarModule,
    PortalModule,
    OverlayModule,
    NgxTippyModule,
  ], providers: [
        provideClientHydration(),
        provideHttpClient(withFetch()),
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [{
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.gAuthClientId, {
                            scopes: ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile'],
                            oneTapEnabled: false,
                        })
                    }]
            } as SocialAuthServiceConfig,
        },
        DialogService,
        ConfirmationService,
        MessageService,
        provideHttpClient(withInterceptorsFromDi()),
        provideTippyConfig({
          defaultVariation: 'tooltip',
          variations: {
            tooltip: tooltipVariation,
            popper: popperVariation,
        },
    })
    ] })
export class AppModule { }
