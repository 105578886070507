import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloService } from "../services/apollo.service";


@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (apolloService: ApolloService) => apolloService.createApollo(),
      deps: [ApolloService],
    },
  ],
})

export class GraphQLModule {

}
