import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, firstValueFrom, lastValueFrom } from "rxjs";
import { HttpLink } from "apollo-angular/http";
import { ActionsSubject, Store } from "@ngrx/store";
import { ErrorCodeService } from "./error-code.service";
import { ApolloClientOptions, ApolloLink, fromPromise, InMemoryCache, Operation, DefaultOptions } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { selectAuthCredentials } from "../../features/auth/store/auth.selectors";
import { take } from "rxjs/operators";
import { onError } from "@apollo/client/link/error";
import { logout, refreshToken, refreshTokenFailure, refreshTokenSuccess } from "../../features/auth/store/auth.actions";
import { Actions, ofType } from "@ngrx/effects";
import { GraphQLError } from "graphql/error";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApolloService {

  private isRefreshing = false;
  private refreshTokenSubject = new BehaviorSubject<string | null>(null);

  private whitelistedGraphqlRequests: string[] = [];

  private refreshTokenCompleted$ = this.updates$.pipe(ofType(refreshTokenSuccess, refreshTokenFailure));

  constructor(
    private httpLink: HttpLink,
    private store: Store,
    private errorCodeService: ErrorCodeService,
    private updates$: ActionsSubject
  ) {
  }

  private addTokenToRequest(operation: Operation, token: string): Operation {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    return operation;
  }

  private createAuthLink() {
    return setContext(async (operation, context) => {
      const tokenPair = await firstValueFrom(this.store.select(selectAuthCredentials));

      console.log('[ApolloService]', operation.operationName, 'Signing using Token-Pair', tokenPair?.id, tokenPair?.accessToken);

      if ('headers' in context && 'Authorization' in context['headers']) return context;
      if (operation.operationName && this.whitelistedGraphqlRequests.includes(operation.operationName)) return context;
      if (!tokenPair) return context;

      return {...context, headers: {...context['headers'], Authorization: `Bearer ${tokenPair.accessToken}`}};
    });
  }

  private createErrorLink() {
    return onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const { message, locations, path, extensions } of graphQLErrors) {
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);

          const currentAccessToken = this.getCurrentAccessTokenFromOperation(operation);

          if (extensions['code'] === 'UNAUTHENTICATED' && !this.whitelistedGraphqlRequests.includes(operation.operationName) && currentAccessToken) {
            if (!this.isRefreshing) {
              this.isRefreshing = true;
              this.refreshTokenSubject.next(null);

              this.store.dispatch(refreshToken());

              return fromPromise(
                firstValueFrom(this.refreshTokenCompleted$).then(async (result) => {
                  if (result.type === refreshTokenFailure.type) {
                    console.error('Error refreshing token', result.error);
                    this.store.dispatch(logout());
                    this.isRefreshing = false;
                    this.refreshTokenSubject.next(null);

                    return forward(operation);
                  }

                  const refreshedTokenPair = result.credentials;
                  // console.log('[ApolloService]', operation.operationName, 'Refreshed Token-Pair:', refreshedTokenPair?.id, refreshedTokenPair?.accessToken);

                  if (!refreshedTokenPair || refreshedTokenPair.accessToken == currentAccessToken) {
                    console.error('No refreshed access token returned');
                    this.store.dispatch(logout());
                    throw Error('No refreshed access token returned');
                  }
                  this.isRefreshing = false;
                  this.refreshTokenSubject.next(refreshedTokenPair.accessToken);

                  return forward(this.addTokenToRequest(operation, refreshedTokenPair.accessToken));
                })
              ).flatMap(() => forward(operation));
            } else {
              return fromPromise(
                new Promise(resolve => {
                  this.refreshTokenSubject.subscribe(token => {
                    // console.log('[ApolloService]', operation.operationName, 'Using Refreshed Token-Pair:', token);
                    if (token) {
                      resolve(this.addTokenToRequest(operation, token));
                    }
                  });
                })
              ).flatMap(() => forward(operation));
            }
          }
        }
      }

      if (networkError) {
        console.debug(`[Network error]: ${networkError}`);
      }

      return forward(operation);
    });
  }

  private getCurrentAccessTokenFromOperation(operation: Operation): string | undefined {
    if (!operation.getContext()['headers']) return undefined;

    const authHeader = operation.getContext()['headers'].Authorization;
    if (!authHeader) return undefined;
    return authHeader.split(' ')[1];
  }

  private createFormatErrorLink() {
    return  new ApolloLink((operation, forward) => {
      return forward(operation).map(response => {
        // Flatten the error to give detailed error message
        // Note: for admin only
        if (response.errors) {
          const errorCode = this.errorCodeService.getErrorCode(response.errors[0] as GraphQLError);
          console.log('errorCode', errorCode);
          let message = this.errorCodeService.getErrorMessageFromCode(errorCode);
          response.errors[0].message = message;
        }
        return response;
      });
    });
  }

  private defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    }
  }

  createApollo(): ApolloClientOptions<any> {
    const authLink = this.createAuthLink();
    const formatErrorLink = this.createFormatErrorLink();
    const errorLink = this.createErrorLink();
    const httpLink = this.httpLink.create({ uri: environment.serverUrl + environment.graphQLEndpoint });

    const link = ApolloLink.from([formatErrorLink, errorLink, authLink, httpLink]);

    const cache = new InMemoryCache();

    return {
      link,
      cache,
      defaultOptions: this.defaultOptions
    };
  }
}
