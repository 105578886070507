<router-outlet></router-outlet>

<div class="loading-overlay" *ngIf="guardLoading">
  <!-- *ngIf="asyncInitializationService.tasksArePending$ | async"  -->
  <div class="flex flex-column">
    <div class="flex align-items-center mb-4">
      <img alt="Logo" src="/assets/logo.svg" style="height: 60px;">
    </div>
    <div class="">
      <p-progressBar [style]="{ height: '4px' }" mode="indeterminate"></p-progressBar>
    </div>
  </div>
</div>

<app-config *ngIf="showAppConfig"></app-config>
<p-toast [breakpoints]="{'768px': {width: '100%', right: '0', left: '0'}}"></p-toast>
<p-confirmDialog />

